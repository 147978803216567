<template>
  <div class="dark:text-gray-50">
    Welcome!!!, 一个缓慢成长的前端程序猿👨🏻‍💻。

    <!-- <PrimaryButton class="active:animate-toggle">1</PrimaryButton>
    <PrimaryButton class="active:spin-self">1</PrimaryButton>
    <PrimaryButton class="active:spin-plugin">1</PrimaryButton> -->
  </div>
</template>

<script>
// import PrimaryButton from '@/components/buttons/PrimaryButton'

export default {
  name: 'Home',

  components: {
    // PrimaryButton,
  },

  setup () {

    return {

    }
  }
}
</script>
